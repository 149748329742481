import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import { i18n } from './i18n';
import { createPinia } from 'pinia'
import { interceptor } from '@dutypay/utilities';
import ElementPlus from "element-plus";
import 'element-plus/theme-chalk/display.css';
import PopupManager from 'element-plus';
import DesignSystem from '@dutypay/design-system';
import ComponentsLibrary from '@dutypay/components-library';

const pinia = createPinia()
const app = createApp(App);
app.config.globalProperties.$http = interceptor;
app
    .use(router)
    .use(pinia)
    .use(i18n)
    .use(ElementPlus)
    .use(PopupManager, {
        zIndex:3050 // Notifications z-index
    })
    .use(DesignSystem)
    .use(ComponentsLibrary);

    import pages from "@dutypay/prod-ruleshub-pages"; app.use(pages, {router, app, i18n});

    app.mount("#app");

app.provide('router', router);




